import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function options(selectedOptions = {}) {
  return {
    responsive: true,
    plugins: {
      // legend: {
      //   position: 'top',
      // },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    }
  }
};

const labels = ['January','February','March','April','May','June','July','August','September','October','November','December'];

function formattedData(rawEntries) {
  const dateLabels = rawEntries.map(weight => weight.created_at)
  const months = mapMonths(dateLabels)
  const weights = rawEntries.map(weight => weight.filtered_message_data)
  console.log('months:', months)
  console.log('labels:', labels)
  console.log('weights:', weights)

  return {
    months,
    datasets: [
      {
        label: '90 Days',
        data: weights,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ]
  }
};

function mapMonths(dateLabels) {
  let months = []

  dateLabels.forEach(date => {
    const parsedDate = new Date(date)
    months.push(labels[parsedDate.getMonth()])
  })

  return months
}

function transformWeightData(weightData) {
  console.log('weightData:', weightData)
  const zeroFiltered = weightData.filter(weight => weight.filtered_message_data > 0)
  const midMonthFilter = zeroFiltered.filter(weight => weight.created_at.includes('15'))

  return formattedData(midMonthFilter)
}

function chatChart(weightData) {
  return <Line options={options()} data={transformWeightData(weightData)} />;
}

export { 
  chatChart
}
