import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon';
import withStyles from '@material-ui/core/styles/withStyles'

// UserProfile
import ShowUserProfile from './ShowUserProfile'
import EditUserProfile from './EditUserProfile'

import { mapTitleFromCode } from '../../lib/utilities/titleMappers'

import styles from './userProfileStyles'

import { 
  fetchUser,
  fetchProfile,
  updateProfile
} from '../../lib/api_utilities/userApiUtilities'

import { fetchEthnicities, fetchTimeZones } from '../../lib/api_utilities/referenceDataApiUtilities'
import { supportedCountries, supportedGenders } from '../../constants/supportedProfileFields'

class UserProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: localStorage.getItem('token'),
      userData: {},
      profileData: {},
      loadingUser: true,
      loadingProfile: true,
      fetchingRefEthnicities: true,
      fetchingRefTimeZones: true,
      refTimeZones: {},
      showEdit: false,
      apiErrors: null 
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() { 
    this.fetchUserData()
    this.fetchProfileData()
    this.fetchRefEthnicities() 
    this.fetchRefTimeZones()
  }

  fetchUserData() {
    fetchUser(this.state.token).then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            userData: JSON.parse(json),
            loadingUser: false
          })   
        })
      }
    })
  }

  fetchProfileData() {
    fetchProfile(this.state.token).then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            profileData: JSON.parse(json),
            loadingProfile: false
          })   
        })
      }
    })
  }

  fetchRefEthnicities() {
    fetchEthnicities().then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            refEthnicities: JSON.parse(json),
            fetchingRefEthnicities: false
          })   
        })
      }
    })
  }

  fetchRefTimeZones() {
    fetchTimeZones().then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            refTimeZones: JSON.parse(json),
            fetchingRefTimeZones: false
          })   
        })
      }
    })
  }

  updateProfileData() {
    if(this.state.showEdit === true) {
      updateProfile(this.state.token, this.state.profileData).then(response => {
        if (response.ok) {
          response.text().then(json => {
            this.setState({
              profileData: JSON.parse(json),
              showEdit: false,
              apiErrors: null
            })   
          })
        } else {
          this.setState({apiErrors: 'Something went wrong. Please try again.'})
        }
      })
    }
  }

  renderApiErrors() {
    if (this.state.apiErrors === null) {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.apiErrors}
      </div>
    )
  }

  handleChange(event) {
    this.setState({
      profileData: Object.assign(
        this.state.profileData,
        {[event.target.name]: event.target.value}
      )
    })
  }

  saveProfileChange() {
    if(this.state.showEdit === true) {
      this.updateProfileData()
    } else {
      this.setState({showEdit: !this.state.showEdit})
    }
  }

  render() {
    const { classes } = this.props
    let loadingState = this.state.loadingUser || this.state.loadingProfile || this.state.fetchingRefEthnicities || this.state.fetchingRefEthnicities || this.state.fetchingRefTimeZones
    let ethnicity, country, profile, user, gender, timezone

    if (!loadingState) {
      ethnicity = mapTitleFromCode(this.state.profileData.ethnicity, this.state.refEthnicities)
      country = mapTitleFromCode(this.state.profileData.country_code, supportedCountries)
      gender = mapTitleFromCode(this.state.profileData.gender, supportedGenders)
      timezone = mapTitleFromCode(this.state.profileData.timezone, this.state.refTimeZones)
      profile = this.state.profileData
      user = this.state.userData
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Box className={classes.titleBoxWithButton}>
            <Typography component='h6' variant='h6'>
              User Profile
            </Typography>
            <Button
                size='small'
                color='primary'
                className={classes.edit}
                onClick={() => { this.saveProfileChange() }}
              >
              <Icon className={this.props.classes.leftIcon} fontSize='small'>{this.state.showEdit ? 'save' : 'edit'}</Icon>
              {this.state.showEdit ? 'Save' : 'Edit'}
            </Button>
          </Box>
          { this.renderApiErrors() }
          {
            this.state.showEdit ? 
            <EditUserProfile 
              classes={classes}
              user={user}
              profile={profile}
              country={country}
              ethnicity={ethnicity}
              gender={gender}
              loadingState={loadingState}
              refEthnicities={this.state.refEthnicities}
              refTimeZones={this.state.refTimeZones}
              handleChange={this.handleChange}
            /> :
            <ShowUserProfile 
              classes={classes}
              user={user}
              profile={profile}
              country={country}
              ethnicity={ethnicity}
              timezone={timezone}
              loadingState={loadingState}
            />
          }
        </Paper>
      </main>
    )
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UserProfile)
