import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import styles from './registrationStyles'

class BuddiesTable extends Component {
  renderRemoveIcon(buddy) {
    if (this.props.disableRemoveIcon === true) {
      return(<TableCell align="right"/>)
    }

    return (
      <TableCell align="right" onClick={() => this.props.removeBuddy(buddy)}>
       <RemoveCircleIcon className={this.props.classes.addCircle} />
      </TableCell>
      
    )
  }

  render() {
    const { classes } = this.props
    const buddies = this.props.buddies

    return (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} size="small" aria-label="buddies table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.keys(buddies).map(buddy => 
                {
                  return (
                    <TableRow key={buddy}>
                      <TableCell component="th" scope="buddy">{buddies[buddy].first_name}</TableCell>
                      <TableCell align="left">{buddies[buddy].phone}</TableCell>
                      {this.renderRemoveIcon(buddy)}
                    </TableRow>
                  )
                }
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

BuddiesTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BuddiesTable)
