const registrationControllerState = {
  cached_state: {},
  first_name: '',
  phone: '',
  missing_first_name: false,
  invite_token_id: '',
  api_errors: '',
  user_id: '',
  submitting: false,
  valid_invite_token: false,
  validating_token: true,
  buddies: {},
  current_buddy_first_name: '',
  current_buddy_phone: '',
  ethnicity: '',
  timezone: '',
  country_code: '',
  weight_units: '',
  birthdate: '',
  height: '',
  target_weight: '',
  height_units: '',
  gender: '',
  step: 1
}

export { 
  registrationControllerState 
}
