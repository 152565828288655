import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './lib/serviceWorker';
import { Provider } from 'mobx-react'

import './index.css';

import App from './components/App';
// import Main from './Main'

import LoginPage from './components/login/LoginPage'
import Dashboard from './components/dashboard/Dashboard'
import ChatDetail from './components/chat_detail/ChatDetail'
import EditMessages from './components/edit_messages/EditMessages'
import RegistrationController from './components/registration/RegistrationController'
import RegistrationSuccess from './components/registration/RegistrationSuccess'
import RequestInviteToken from './components/invite_token/RequestInviteToken'
import RequestInviteTokenSuccess from './components/invite_token/RequestInviteTokenSuccess'
import ForgotPassword from './components/forgot_password/ForgotPassword'
import ForgotPasswordSuccess from './components/forgot_password/ForgotPasswordSuccess'
import PasswordReset from './components/password_reset/PasswordReset'
import PasswordResetSuccess from './components/password_reset/PasswordResetSuccess'
import UserProfile from './components/user_profile/UserProfile'

import store from './stores/Store'

const routing = (
  <Provider store={ store }>
    <Router>
      <div>
        <Route path='/' component={App} />
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/chats/:id' component={ChatDetail} />
        <Route path='/edit-messages/:id' component={EditMessages} />
        <Route path='/login' component={LoginPage} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/forgot-password-success' component={ForgotPasswordSuccess} />
        <Route path='/password-reset/:passwordResetToken' component={PasswordReset} />
        <Route path='/password-reset-success' component={PasswordResetSuccess} />
        <Route path='/request-invite' component={RequestInviteToken} />
        <Route path='/request-invite-success' component={RequestInviteTokenSuccess} />
        <Route path='/registration/:inviteTokenId' component={RegistrationController} />
        <Route path='/registration-success' component={RegistrationSuccess} />
        <Route path='/user-profile' component={UserProfile} />
      </div>
    </Router>
  </Provider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
