// External
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// MUI
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box'

// Internal
import styles from './userProfileStyles'
import EditFormControlDropDown from '../registration/EditFormControlDropDown'
import { 
  supportedWeightUnits,
  supportedHeightUnits,
  supportedCountries,
  supportedGenders
} from '../../constants/supportedProfileFields'

import { BIRTHDATE_PATTERN } from '../../constants/regExpPatterns'


class RegistrationEditProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      birthdateError: null,
      formErrors: null
    }

    this.onBirthdateChange = this.onBirthdateChange.bind(this)
    this.renderFormErrors = this.renderFormErrors.bind(this)
  }


  onBirthdateChange(event) {
    if (BIRTHDATE_PATTERN.test(event.target.value)) {
      this.setState({birthdateError: null, formErrors: null})
    } else {
      this.setState({birthdateError: 'Birthdate is not in a valid format.', formErrors: true})
    }

    this.props.handleChange(event)  
  }

  renderFormErrors() {
    if (this.state.formErrors === null) {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        Please fix the errors above.
      </div>
    )
  }

  renderBirthdateErrors() {
    if (this.state.birthdateError === null) {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.birthdateError}
      </div>
    )
  }

  render() {
    const { classes, profile, refTimeZones } = this.props
    
    return (
      <form className={classes.form}>
        <EditFormControlDropDown
          value={profile.timezone}
          valueName='timezone'
          valueTitle='Time Zone'
          handleChange={this.props.handleChange}
          refMapping={refTimeZones}
          fullWidth={true}
        />
        <EditFormControlDropDown
          value={profile.country_code}
          valueName='country_code'
          valueTitle='Country'
          handleChange={this.props.handleChange}
          refMapping={supportedCountries}
          fullWidth={true}
        />
        <EditFormControlDropDown
          value={profile.ethnicity}
          valueName='ethnicity'
          valueTitle='Ethnicity'
          handleChange={this.props.handleChange}
          refMapping={this.props.refEthnicities}
          fullWidth={true}
        />
        <Box>
          <FormControl className={classes.oneLineFields}>
            <TextField
              required
              id='birthdate' 
              name='birthdate'
              value={profile.birthdate}
              onChange={this.onBirthdateChange}
              label='Birthdate (mm/dd/yy)'
            />

            {this.renderBirthdateErrors()}
          </FormControl>
          <EditFormControlDropDown
            value={profile.gender}
            valueName='gender'
            valueTitle='Gender'
            handleChange={this.props.handleChange}
            refMapping={supportedGenders}
          />
        </Box>
        <Box>
          <FormControl className={classes.oneLineFields}>
            <InputLabel id='target_weight'>Target Weight</InputLabel>
            <Input 
              id='target_weight' 
              name='target_weight'
              value={profile.target_weight}
              onChange={this.props.handleChange}
            />
          </FormControl>
          <EditFormControlDropDown
            value={profile.weight_units}
            valueName='weight_units'
            valueTitle='Weight Units'
            handleChange={this.props.handleChange}
            refMapping={supportedWeightUnits}
          />
        </Box>
        <Box>
          <FormControl className={classes.oneLineFields}>
            <InputLabel id='height'>Height</InputLabel>
            <Input 
              id='height' 
              name='height'
              value={profile.height}
              onChange={this.props.handleChange}
            />
          </FormControl>
          <EditFormControlDropDown
            value={profile.height_units}
            valueName='height_units'
            valueTitle='Height Units'
            handleChange={this.props.handleChange}
            refMapping={supportedHeightUnits}
          />
        </Box>
      </form>
    )
  }
}

RegistrationEditProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegistrationEditProfile)
