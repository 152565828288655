import { PHONE_PATTERN } from '../../constants/regExpPatterns'

function cleanPhone(phone) {
  let cleanedPhone = phone.replaceAll('-', '')
    .replaceAll('.', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll(' ', '')

  return cleanedPhone
}

function validPhoneNumber(phone) {
  return PHONE_PATTERN.test(phone)
}

export { 
  cleanPhone,
  validPhoneNumber
}
