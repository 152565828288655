import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// Table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import BasicTableRow from '../shared/BasicTableRow'

// Internal
import styles from './chatDetailStyles'
import { 
  fetchChat,
  fetchChatMessages
} from '../../lib/api_utilities/userApiUtilities'
import { chatChart } from '../chart/chatChart'


class ChatDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userChat: {chat_user_types: []},
      chatMessages: [],
      loading: true,
      loadingMessages: true
    }
  }

  componentDidMount() { 
    this.fetchUserChat()
    this.fetchMessages()
  }

  chatId() {
    return this.props.match.params.id
  }

  fetchUserChat() {
    fetchChat(this.chatId()).then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            userChat: JSON.parse(json),
            loading: false
          })   
        })
      }
    })
  }

  fetchMessages() {
    fetchChatMessages(this.chatId(), 365).then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            chatMessages: JSON.parse(json),
            loadingMessages: false
          })   
        })
      }
    })
  }


  goToEditMessages() {
    this.props.history.push(`/edit-messages/${this.chatId()}`)
  }

  chatTitle = chat_user_types => {
    if (this.state.loading === true) {
      return null
    }

    let coachee;

    chat_user_types.forEach(user => { 
      if (user.role === 'coachee') {
        coachee = user.first_name
      }
    })

    return `${coachee}'s Chat`
  }

  render() {
    const { classes } = this.props
    const userChat = this.state.userChat

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Box className={classes.chatTitle}>
            <Typography component='h6' variant='h6'>
              { this.chatTitle(userChat.chat_user_types) }
            </Typography>
            <Typography>
              <Link onClick={this.goToChat}>
                <Icon className={classes.leftIcon}>settings</Icon>
                Chat Settings
              </Link>
            </Typography>
          </Box>
          { this.state.loadingMessages === true ? null : chatChart(this.state.chatMessages) }
          <Box className={classes.card} key={userChat.id} >
            <Box className={classes.chatTitle}>
              <h3 className={classes.recentWeight}>Recent Entries</h3>
              <Typography>
                <Link onClick={() => {this.goToEditMessages()}}>
                  <Icon className={classes.leftIcon}>edit</Icon>
                  Edit
                </Link>
              </Typography>
            </Box>
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} size="small" aria-label="buddies table">
                  {
                    this.state.loadingMessages === true ? 
                      <TableBody/> :
                      <TableBody>
                       {
                          this.state.chatMessages.map(message => {
                            return(
                              <BasicTableRow 
                                title={message.created_at} 
                                value={message.filtered_message_data}
                                key={message.id} 
                              />
                            )
                          })
                        }
                      </TableBody>
                  }
                </Table>
              </TableContainer>
          </Box>
        </Paper>
      </main>
    )
  }
}

ChatDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ChatDetail)
