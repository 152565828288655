import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// Internal files
import styles from './forgotPasswordStyles'
import { PHONE_PATTERN } from '../../constants/regExpPatterns'
import { requestForgotPasswordLink } from '../../lib/api_utilities/passwordApiUtilities'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone: '',
      api_errors: ''
    }
    
    this.handleChange = this.handleChange.bind(this)
    this.handleApiErrors = this.handleApiErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.renderApiErrors = this.renderApiErrors.bind(this)
    this.resetApiErrors = this.resetApiErrors.bind(this)
  }

  handleSubmit(event) {
    if (this.state.api_errors === '') {
      this.resetApiErrors()
      requestForgotPasswordLink({phone: this.state.phone}).then(response => {
        if (response.ok) {
          this.props.history.push('/forgot-password-success')
        } else if (response.status === 400) {
          response.text().then(json => {
            // Intentional console log for debugging
            console.log('The server returned errors:', JSON.parse(json).errors.message)
            this.handleApiErrors('Something went wrong. Please try again.')
          })
        }
      })
    }
  }

  resetApiErrors() {
    this.setState({api_errors: ''})
  }

  handleApiErrors(errorText) {
    this.setState({api_errors: errorText})
  }

  handleChange(event) {    
    if (PHONE_PATTERN.test(event.target.value)) {
      this.resetApiErrors()
      this.setState({[event.target.name]: event.target.value})  
    } else {
      this.handleApiErrors('Phone number is not in a valid format.')
    }
  }

  renderApiErrors() {
    if (this.state.api_errors === '') {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.api_errors}
      </div>
    )
  }

  disableSubmit() {
    return this.state.api_errors !== ''
  }

  render() {
    const { classes } = this.props

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component='h6' variant='h6'>
            Forgot Your Password? 
          </Typography>
          <p>  
            Please enter your phone number to get a reset link.
          </p>
          <form className={classes.form}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='phone'>Phone Number</InputLabel>
              <Input 
                required
                id='phone' 
                name='phone' 
                autoComplete='phone' 
                autoFocus
                onChange={this.handleChange}
              />
              <FormHelperText id="my-helper-text">
              Must be a valid 10-digit US phone number e.g. 212-212-2121
              </FormHelperText>
            </FormControl>
            {this.renderApiErrors()}
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={this.disableSubmit()}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </main>
    )
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ForgotPassword)
