import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

// Table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import BasicTableRow from '../shared/BasicTableRow'

import styles from './userProfileStyles'

class ShowUserProfile extends Component {
  render() {
    const { classes, user, profile, country, ethnicity, loadingState, timezone } = this.props

    return (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} size="small" aria-label="buddies table">
          {
            loadingState ? 
              <TableBody/>
             :
            <TableBody>
              <BasicTableRow title='Name' value={user.first_name} />
              <BasicTableRow title='Phone' value={user.phone} />
              <BasicTableRow title='Birthday' value={profile.birthdate} />
              <BasicTableRow title='Height' value={`${profile.height} ${profile.height_units}`} />
              <BasicTableRow title='Ethnicity' value={ethnicity} />
              <BasicTableRow title='Timezone' value={timezone} />
              <BasicTableRow title='Country' value={country} />
              <BasicTableRow title='Target Weight' value={`${profile.target_weight} ${profile.weight_units}`}/>
            </TableBody>
          }
        </Table>
      </TableContainer>
    )
  }
}

ShowUserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ShowUserProfile)
