// External
import React, { Component } from 'react'
import { v4 } from 'uuid'

// MUI
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles'

// Internal
import styles from '../registration/registrationStyles'

class EditFormControlDropDown extends Component {
  render() {
    const { classes } = this.props

    return (
      <FormControl className={classes.formControl} fullWidth={this.props.fullWidth}>
        <InputLabel id={this.props.valueName}>{this.props.valueTitle}</InputLabel>
        <Select
          required
          labelId={this.props.valueName}
          id={this.props.valueName}
          name={this.props.valueName}
          value={this.props.value}
          onChange={this.props.handleChange}
          className={classes.selectEmpty}
        >
          {
            this.props.refMapping.map(refObject => (
              <MenuItem 
                key={refObject.id || v4()} 
                value={refObject.code}>
                {refObject.title}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(styles)(EditFormControlDropDown)
