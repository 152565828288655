// External
import React, { Component } from 'react'

// MUI
import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles'

// Internal
import styles from './registrationStyles'

class ConfirmationTableRow extends Component {
  render() {
    const { classes } = this.props

    return (
      <TableRow>
        <TableCell align="right" className={classes.tableRowKey}>
          <Box fontWeight="fontWeightBold">
            {this.props.title}
          </Box>
        </TableCell>
        <TableCell align="left" className={classes.tableRowValue}>
          {this.props.value}
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(ConfirmationTableRow)
