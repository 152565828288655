function registrationBody(stateParams) {
  return {
    first_name: stateParams.first_name,
    participants: mapBuddiesToParticipants(stateParams.buddies),
    profile: {
      ethnicity: stateParams.ethnicity,
      timezone: stateParams.timezone,
      country_code: stateParams.country_code,
      weight_units: stateParams.weight_units,
      birthdate: stateParams.birthdate,
      height: stateParams.height,
      target_weight: stateParams.target_weight,
      height_units: stateParams.height_units,
      gender: stateParams.gender
    }
  }
}

function mapBuddiesToParticipants(buddies) {
  let participants = []
  
  // only send the cleaned phone number to the API
  Object.keys(buddies).forEach(buddyPhone => {
    participants.push(
      {
        phone: buddyPhone, 
        first_name: buddies[buddyPhone].first_name
      }
    )
  })

  return participants
}

function saveStateBody(stateParams) {
  return {
    step: 1,
    first_name: stateParams.first_name,
    buddies: stateParams.buddies,
    current_buddy_phone: stateParams.current_buddy_phone,
    current_buddy_first_name: stateParams.current_buddy_first_name,
    ethnicity: stateParams.ethnicity,
    timezone: stateParams.timezone,
    country_code: stateParams.country_code,
    weight_units: stateParams.weight_units,
    birthdate: stateParams.birthdate,
    height: stateParams.height,
    target_weight: stateParams.target_weight,
    height_units: stateParams.height_units,
    gender: stateParams.gender
  }
}

export { 
  registrationBody,
  saveStateBody
}
