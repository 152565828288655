// External
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// MUI
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

// Internal
import styles from './registrationStyles'
import FormFooterBox from './FormFooterBox'
import EditFormControlDropDown from './EditFormControlDropDown'
import TitleBox from './TitleBox'
import { BIRTHDATE_PATTERN } from '../../constants/regExpPatterns'
import { 
  supportedWeightUnits,
  supportedHeightUnits,
  supportedCountries,
  supportedGenders
} from './supportedProfileFields'
import { 
  fetchEthnicities,
  fetchTimeZones
} from '../../lib/api_utilities/referenceDataApiUtilities'



class RegistrationEditProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      refEthnicities: [],
      refTimeZones: [],
      fetchingRefEthnicities: true,
      fetchingRefTimeZones: true,
      birthdateError: null,
      formErrors: null
    }

    this.onBirthdateChange = this.onBirthdateChange.bind(this)
    this.renderFormErrors = this.renderFormErrors.bind(this)
    this.onNextStep = this.onNextStep.bind(this)
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchRefEthnicities(),
      this.fetchRefTimeZones()
    ])
  }

  fetchRefEthnicities() {
    fetchEthnicities().then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            refEthnicities: JSON.parse(json),
            fetchingRefEthnicities: false

          })   
        })
      }
    })
  }

  fetchRefTimeZones() {
    fetchTimeZones().then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            refTimeZones: JSON.parse(json),
            fetchingRefTimeZones: false
          })   
        })
      }
    })
  }

  onBirthdateChange(event) {    
    if (BIRTHDATE_PATTERN.test(event.target.value)) {
      this.setState({birthdateError: null, formErrors: null})
      this.props.handleChange(event)  
    } else {
      this.setState({birthdateError: 'Birthdate is not in a valid format.', formErrors: true})
      this.props.handleChange(event)
    }
  }

  onNextStep() {
    if (this.state.formErrors === null) {
      this.props.nextStep()
    }
  }

  renderFormErrors() {
    if (this.state.formErrors === null) {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        Please fix the errors above.
      </div>
    )
  }

  renderBirthdateErrors() {
    if (this.state.birthdateError === null) {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.birthdateError}
      </div>
    )
  }

  render() {
    const fetchingRefEthnicities = this.state.fetchingRefEthnicities
    const fetchingRefTimeZones = this.state.fetchingRefTimeZones
    if (fetchingRefEthnicities === true || fetchingRefTimeZones === true) {
      return null
    }
    
    const { classes } = this.props
    // remap Api keys to string
    const refEthnicities = this.state.refEthnicities
    const refTimeZones = this.state.refTimeZones

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <TitleBox currentStep={this.props.values.step} />
          <form className={classes.form}>
            <Button
              size='small'
              variant='contained'
              color='secondary'
              className={classes.submit}
              onClick={this.props.resetProfileState}
              fullWidth
            > 
              Tap Or Click To Reset Form
            </Button>
            <EditFormControlDropDown
              value={this.props.values.timezone}
              valueName='timezone'
              valueTitle='Time Zone'
              handleChange={this.props.handleChange}
              refMapping={refTimeZones}
              fullWidth={true}
            />
            <EditFormControlDropDown
              value={this.props.values.country_code}
              valueName='country_code'
              valueTitle='Country'
              handleChange={this.props.handleChange}
              refMapping={supportedCountries}
              fullWidth={true}
            />
            <EditFormControlDropDown
              value={this.props.values.ethnicity}
              valueName='ethnicity'
              valueTitle='Ethnicity'
              handleChange={this.props.handleChange}
              refMapping={refEthnicities}
              fullWidth={true}
            />
            <FormControl className={classes.formControl} >
              <TextField
                required
                id='birthdate' 
                name='birthdate'
                value={this.props.values.birthdate}
                onChange={this.onBirthdateChange}
                label='Birthdate (mm/dd/yy)'
              />

              {this.renderBirthdateErrors()}
            </FormControl>
            <EditFormControlDropDown
              value={this.props.values.gender}
              valueName='gender'
              valueTitle='Gender'
              handleChange={this.props.handleChange}
              refMapping={supportedGenders}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id='target_weight'>Target Weight</InputLabel>
              <Input 
                id='target_weight' 
                name='target_weight'
                value={this.props.values.target_weight}
                onChange={this.props.handleChange}
              />
            </FormControl>
            <EditFormControlDropDown
              value={this.props.values.weight_units}
              valueName='weight_units'
              valueTitle='Weight Units'
              handleChange={this.props.handleChange}
              refMapping={supportedWeightUnits}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id='height'>Height</InputLabel>
              <Input 
                id='height' 
                name='height'
                value={this.props.values.height}
                onChange={this.props.handleChange}
              />
            </FormControl>
            <EditFormControlDropDown
              value={this.props.values.height_units}
              valueName='height_units'
              valueTitle='Height Units'
              handleChange={this.props.handleChange}
              refMapping={supportedHeightUnits}
            />
          </form>
          <FormFooterBox
            nextStep={this.props.nextStep}
            previousStep={this.props.previousStep}
            nextStepDisabled={this.state.formErrors === true}
          />

          {this.renderFormErrors()}
        </Paper>
      </main>
    )
  }
}

RegistrationEditProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegistrationEditProfile)
