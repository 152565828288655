import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Modal from '@material-ui/core/Modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'

// Table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import BasicTableRow from '../shared/BasicTableRow'

// Internal
import EditFormControlDropDown from '../shared/EditFormControlDropDown'
import { monthMapping, yearMapping } from '../../constants/selectFieldMappings'
import styles from './editMessagesStyles'
import { 
  fetchSelectedChatMessages,
  updateChatMessage
} from '../../lib/api_utilities/userApiUtilities'


class EditMessages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      chatMessages: [],
      loadingMessages: true,
      showEdit: false,
      currentEdit: '',
      currentEditValue: '',
      selection: {
        month: this.currentDate().getMonth() + 1,
        year: this.currentDate().getUTCFullYear()
      }
    }

    this.changeSelection = this.changeSelection.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.hideEdit = this.hideEdit.bind(this)
    this.handleEditChange = this.handleEditChange.bind(this)
  }

   componentDidMount() { 
    this.fetchMessages(this.state.selection)
  }

  currentDate() {
    return new Date();
  }

  changeSelection(event) {
    const initialSelection = this.state.selection[event.target.name]
    
    if (initialSelection !== event.target.value) {
      const newState = Object.assign({}, this.state.selection, {[event.target.name]: event.target.value})
      this.setState({selection: newState})
      this.fetchMessages(newState)
    }
  }

  chatId() {
    return this.props.match.params.id
  }

  handleEdit(key, value) {
    this.setState({showEdit: true, currentEdit: key, currentEditValue: value})
  }

  fetchMessages(selection) {
    fetchSelectedChatMessages(this.chatId(), 31, selection).then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            chatMessages: JSON.parse(json),
            loadingMessages: false
          })   
        })
      }
    })
  }

  goToChat() {
    this.props.history.push(`/chats/${this.chatId()}`)
  }

  hideEdit(){
    this.setState({showEdit: false})

    updateChatMessage(this.state.currentEdit, {message_body: this.state.currentEditValue}).then(response => {
      if (response.ok) {
        this.fetchMessages(this.state.selection)
      }
    })
  }

  handleEditChange(event) {
    this.setState({currentEditValue: event.target.value})
  }

  editModal() {
    return(
      <Modal
        open={this.state.showEdit}
        onClose={this.hideEdit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={this.props.classes.editModal}>
          <HighlightOffIcon onClick={this.hideEdit} />
          <form className={this.props.classes.form}>
            <FormControl margin='normal' required fullWidth>
              <Input 
                required
                id='messageValue' 
                name='messageValue' 
                autoFocus 
                value={this.state.currentEditValue}
                onChange={this.handleEditChange}
              />
            </FormControl>
          </form>
        </div>
      </Modal>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Box className={classes.chatTitle}>
            <Typography component='h6' variant='h6'>
              Edit Weight Entries
            </Typography>
            <Typography>
              <Link onClick={() => {this.goToChat()}}>
                <Icon className={classes.leftIcon}>arrow</Icon>
                Back
              </Link>
            </Typography>
          </Box>
          { this.editModal() }
          <form>
            <EditFormControlDropDown
              value={this.state.selection.month}
              valueName='month'
              valueTitle='Month'
              handleChange={this.changeSelection}
              refMapping={monthMapping}
              fullWidth={false}
            />
            <EditFormControlDropDown
              value={this.state.selection.year}
              valueName='year'
              valueTitle='Year'
              handleChange={this.changeSelection}
              refMapping={yearMapping}
              fullWidth={false}
            />
          </form>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} size="small" aria-label="buddies table">
              {
                this.state.loadingMessages === true ? 
                  <TableBody/> :
                  <TableBody>
                   {
                      this.state.chatMessages.map(message => {
                        return(
                          <BasicTableRow 
                            title={message.created_at} 
                            value={message.filtered_message_data}
                            objectId={message.id}
                            key={message.id}
                            edit={true}
                            handleEdit={this.handleEdit}
                          />
                        )
                      })
                    }
                  </TableBody>
              }
            </Table>
          </TableContainer>
        </Paper>
      </main>
    )
  }
}

EditMessages.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditMessages)
