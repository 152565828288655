import { basePostRequest } from './baseApiUtilities'

function requestForgotPasswordLink(phoneBody) {
  return basePostRequest(phoneBody, 'passwords/forgot')
}

function resetPassword(passwordResetToken, passwordBody) {
  const requestBody = {password: passwordBody, password_reset_token: passwordResetToken}

  return basePostRequest(requestBody, 'passwords/reset')
}


export { 
  requestForgotPasswordLink,
  resetPassword
}