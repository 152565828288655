import { makeObservable, observable, action } from "mobx";
import { v4 } from "uuid";

class AuthStore {
  currentUser = {
    isLoggedIn: (localStorage.getItem('loggedIn') === 'true'),
    first_name: null,
    token: localStorage.getItem('token'),
    sessionId: null
  };

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      logOut: action,
      logIn: action
    });
  }

  logIn(first_name, token) {
    this.currentUser = {
      isLoggedIn: true,
      first_name: first_name,
      token: token,
      sessionId: v4()
    };
  }

  logOut() {
    this.currentUser = {
      isLoggedIn: false,
      first_name: null,
      token: null,
      sessionId: null
    };
  }
}

var store = new AuthStore()

export default store
