import React, { Component } from 'react';

// Custom Elements
import MenuAppBar from './menu/MenuAppBar'

import store from '../stores/Store';

class App extends Component {
  render() {
    return (
      <main>
        <MenuAppBar {...this.props} store={ store } />
      </main>
    );
  }
}

export default App;
