const baseRefUrl = 'v1/reference_data/'
const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

function fetchEthnicities() {
  return fetch(`${process.env.REACT_APP_API_HOST}${baseRefUrl}ethnicities`, {
    method: 'GET',
    headers: defaultHeaders,
  })
}

function fetchTimeZones() {
  return fetch(`${process.env.REACT_APP_API_HOST}${baseRefUrl}time_zones`, {
    method: 'GET',
    headers: defaultHeaders,
  })
}


export { 
  fetchEthnicities,
  fetchTimeZones
}
