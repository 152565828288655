import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import withStyles from '@material-ui/core/styles/withStyles'

import styles from './registrationStyles'

class FormFooterBox extends Component {
  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  }

  back = e => {
    e.preventDefault()
    this.props.previousStep()
  }

  render() {
    const { classes } = this.props
    let nextStepDisabled = this.props.nextStepDisabled || false

    return (
      <Box className={classes.submitBox}>
        <Button
          size='large'
          color='secondary'
          className={classes.submit}
          onClick={this.back}
        >
          <ArrowBackIcon className={classes.arrowBackIcon} />  
          Back
        </Button>
        <Button
          size='large'
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={this.continue}
          disabled={nextStepDisabled}
        > 
          Next Step
          <ArrowForwardIcon className={classes.arrowIcon} /> 
        </Button>
      </Box>
    )
    
  }
}

FormFooterBox.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FormFooterBox)
