import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'

import styles from './registrationStyles'
import { createChatValidation } from '../../lib/api_utilities/registrationApiUtilities'

import TitleBox from './TitleBox'
import BuddiesTable from './BuddiesTable'
import AddBuddy from './AddBuddy'
import FormFooterBox from './FormFooterBox'

class RegistrationBuddiesDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show_add_buddy: false,
      api_errors: []
    }

    this.nextStep = this.nextStep.bind(this)
    this.validateChatBuddies = this.validateChatBuddies.bind(this)
  }

  buddyPhones() {
    return {phones: Object.keys(this.props.values.buddies).concat(this.props.values.phone)}
  }

  validateChatBuddies(inviteTokenId, buddyPhones) {
    this.setState({api_errors: []})

    createChatValidation(
      inviteTokenId,
      buddyPhones
    ).then(response => {
      if (response.ok) {
        this.props.nextStep()
      } else if (response.status === 400) {
        response.text().then(json => {
          this.setState({api_errors: JSON.parse(json).errors})
        })
      }
    })
  }

  renderBuddiesError() {
    if (this.state.api_errors.length === 0) {
      return null
    }

    return (
      <FormHelperText className={this.props.classes.error}>
        ERROR: {this.state.api_errors[0]} Please change or remove a buddy to continue.
      </FormHelperText>
    )
  }

  handleOpen = () => {
    this.setState({show_add_buddy: true, api_errors: []})
  };

  handleClose = () => {
    this.setState({show_add_buddy: false})
  };

  addBuddyAndClose = (cleanedPhone) => {
    this.props.addBuddy(cleanedPhone)
    this.handleClose()
  }

  nextStep() {
    this.validateChatBuddies(this.props.values.invite_token_id, this.buddyPhones())
  }

  render() {
    const { classes, values } = this.props
    const buddies = values.buddies

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <TitleBox currentStep={this.props.values.step} />
          <Typography variant='body1' className={this.props.classes.buddyIntroBox} >
            You can add a max of 2 buddy participants to your MMS group here. If you make 
            changes to your group after the chat begins then you will needs to re-register
            a new MMS group.
          </Typography>
          <AddBuddy 
            handleOpen={this.handleOpen}
            handleClose={this.handleClose}
            showAddBuddy={this.state.show_add_buddy}
            addBuddy={this.addBuddyAndClose}
            buddies={buddies}
            handleChange={this.props.handleChange}
            cleanPhone={this.props.cleanPhone}
            values={this.props.values}
          />
          <BuddiesTable 
            buddies={buddies} 
            removeBuddy={this.props.removeBuddy}
          />
          {this.renderBuddiesError()}
          <FormFooterBox
            nextStep={this.nextStep}
            nextStepDisabled={false}
            previousStep={this.props.previousStep}
            values={this.props.values}
          />
        </Paper>
      </main>
    )
    
  }
}

RegistrationBuddiesDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(RegistrationBuddiesDetails)

