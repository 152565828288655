import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './registrationStyles'

class RegistrationSuccess extends Component {
  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h6" variant="h6">
            You've successfully registered!
          </Typography>
        </Paper>
      </main>
    )
  }
}
          
RegistrationSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegistrationSuccess);