// External
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// MUI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// Internal
import styles from './registrationStyles'
import titleMap from './titleMap'

class TitleBox extends Component {
  render() {
    const { classes } = this.props

    return (
      <Box className={classes.titleBox} >
        <Typography component='h5' variant='h5'>
          {titleMap[this.props.currentStep]}
        </Typography>
        <Typography component='p' >
          Step {this.props.currentStep} of {Object.keys(titleMap).length}
        </Typography>
      </Box>
    )
  }
}

TitleBox.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TitleBox)
