const monthMapping = [
  {code: '1', title: 'January', enabled: true},
  {code: '2', title: 'February', enabled: true},
  {code: '3', title: 'March', enabled: true},
  {code: '4', title: 'April', enabled: true},
  {code: '5', title: 'May', enabled: true},
  {code: '6', title: 'June', enabled: true},
  {code: '7', title: 'July', enabled: true},
  {code: '8', title: 'August', enabled: true},
  {code: '9', title: 'September', enabled: true},
  {code: '10', title: 'October', enabled: true},
  {code: '11', title: 'November', enabled: true},
  {code: '12', title: 'December', enabled: true}
]

const yearMapping = [
  {code: '2020', title: '2020', enabled: true},
  {code: '2021', title: '2021', enabled: true},
  {code: '2022', title: '2022', enabled: true}
]

export { 
  monthMapping,
  yearMapping
}
