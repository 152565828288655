import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './registrationStyles'
import TitleBox from './TitleBox'

class RegistrationPersonalDetails extends Component {
  continue = e => {
    e.preventDefault()
    this.props.nextStep()
  }

  back = e => {
    e.preventDefault()
    this.props.previousStep()
  }

  missingFirstName() {
    return !this.props.values.first_name.length > 0
  }

  renderNameFieldError() {
    if (!this.missingFirstName()) {
      return null
    }

    return (
       <FormHelperText className={this.props.classes.error}>
          First name is required.
      </FormHelperText>
    )
  }

  render() {
    const { classes } = this.props
    let disableSubmit = this.missingFirstName()

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <TitleBox currentStep={this.props.values.step} />
          <form className={classes.form}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='first_name'>First Name</InputLabel>
              <Input 
                required
                id='first_name' 
                name='first_name' 
                autoComplete='first_name' 
                autoFocus 
                error={true}
                defaultValue={this.props.values.first_name}
                onChange={this.props.handleChange}
              />
              {this.renderNameFieldError()}
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='phone'>Phone Number</InputLabel>
              <Input
                id='phone' 
                name='phone' 
                autoFocus
                value={this.props.values.phone}
                disabled={true}
              />
              <FormHelperText id="my-helper-text">
                This phone number is linked with the invite and cannot be changed.
              </FormHelperText>
            </FormControl>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={disableSubmit}
              onClick={this.continue}
            >
              Edit Profile
              <ArrowForwardIcon className={classes.arrowIcon} /> 
            </Button>
          </form>
        </Paper>
      </main>
    )
    
  }
}

RegistrationPersonalDetails.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegistrationPersonalDetails)
