import React, { Component } from 'react'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'

// MUI
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import styles from './dashboardStyles'

import { 
  fetchChats
} from '../../lib/api_utilities/userApiUtilities'


class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userChats: [],
      loading: true
    }
  }

  componentDidMount() { 
    this.fetchUserChats(localStorage.getItem('token'))
  }

  fetchUserChats(token) {
    fetchChats(token).then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({userChats: JSON.parse(json)})   
        })
      }
    })
  }

  goToChat = id => {
    this.props.history.push(`/chats/${id}`)
  }

  renderChat = chat => {
    const { classes } = this.props
    return(
      <Card className={classes.card} id={chat.id} key={v4()}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Active since:
          </Typography>
          <Typography className={classes.title} gutterBottom>
            {chat.created_at_human_readable}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Users:
          </Typography>
            {
              chat.chat_user_types.map(user => {
                return(
                  <Typography component="p" key={v4()} >{user.first_name}: {user.role}</Typography>
                ) 
              })
            }
        </CardContent>
        <CardActions>
          <Button size="large" onClick={() => { this.goToChat(chat.id) } }>More Details</Button>
        </CardActions>
      </Card>
    )
  }


  render() {
    const { classes } = this.props

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component='h6' variant='h6'>
            My Chats
          </Typography>
          {
            this.state.userChats.map(chat => 
              {
                return(this.renderChat(chat))
              }
            )
          }
        </Paper>
      </main>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Dashboard)
