import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// Internal
import styles from './passwordResetStyles'
import { 
  PASSWORD_REGEX_8_CHAR,
  PASSWORD_REGEX_1_NUM,
  PASSWORD_REGEX_1_SPECIAL_CHAR,
  PASSWORD_REGEX_1_UPPERCASE } from '../../constants/regExpPatterns'
import { resetPassword } from '../../lib/api_utilities/passwordApiUtilities'


class PasswordReset extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone: '',
      apiErrors: '',
      apiErrorStatus: '',
      password: '',
      passwordErrors: {
        char8: true,
        number1: true,
        specialChar: true,
        upperCase: true
      }
    }
    
    this.handleChange = this.handleChange.bind(this)
    this.handleApiErrors = this.handleApiErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.renderApiErrors = this.renderApiErrors.bind(this)
    this.resetApiErrors = this.resetApiErrors.bind(this)
    this.passwordResetToken = this.passwordResetToken.bind(this)
    this.resetErrors = this.resetErrors.bind(this)
    this.renderValidationItem = this.renderValidationItem.bind(this)
    this.goToForgotPassword = this.goToForgotPassword.bind(this)
  }

  passwordResetToken() {
    return this.props.match.params.passwordResetToken
  }

  handleSubmit(event) {
    this.resetApiErrors()

    resetPassword(this.passwordResetToken(), this.state.password).then(response => {
      if (response.ok) {
        this.props.history.push('/password-reset-success')
      } else {
        response.text().then(json => {
          this.handleApiErrors(JSON.parse(json).errors, response.status)
        })
      }
    })
  }

  resetApiErrors() {
    this.setState({apiErrors: ''})
  }

  resetErrors() {
    this.setState({
      passwordErrors: {
        char8: true, 
        number1: true, 
        specialChar: true, 
        upperCase: true
      }
    })
  }

  handleApiErrors(errorText, apiErrorStatus) {
    this.setState({apiErrors: errorText, apiErrorStatus: apiErrorStatus})
  }

  handleChange(event) {  
    this.resetErrors()
    let password = event.target.value
    let { char8, number1, specialChar, upperCase } = this.state.passwordErrors

    if (PASSWORD_REGEX_8_CHAR.test(password)) {
      char8 = false  
    } else {
      char8 = true
    }

    if (PASSWORD_REGEX_1_NUM.test(password)) {
      number1 = false    
    } else {
      number1 = true
    }

    if (PASSWORD_REGEX_1_SPECIAL_CHAR.test(password)) {
      specialChar = false    
    } else {
      specialChar = true
    }

    if (PASSWORD_REGEX_1_UPPERCASE.test(password)) {
      upperCase = false    
    } else {
      upperCase = true
    }

    this.setState({
      password,
      passwordErrors: {char8, number1, specialChar, upperCase}
    })
  }

  renderApiErrors() {
    if (this.state.apiErrors === '') {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.apiErrors}
        {this.renderForgotPassword()}
      </div>
    )
  }

  goToForgotPassword() {
    this.props.history.push('/forgot-password')
  }

  renderForgotPassword() {
    if (this.state.apiErrorStatus === '') {
      return null
    } else if (this.state.apiErrorStatus === 404) {
      return (
        <span className={ this.props.classes.forgotpassword }>
          <Link onClick={ this.goToForgotPassword }>Generate a new link here.</Link>
        </span>
      )
    }
  }

  disableSubmit() {
    const errors = Object.values(this.state.passwordErrors)

    // Filter for unique values from an array
    // https://stackoverflow.com/a/14438954
    const distinct = [...new Set(errors)]

    return (distinct.length === 1 && distinct[0] === false)
  }

  renderValidationItem() {
    let required = []
    const passwordErrors = this.state.passwordErrors
    const validations = {
      upperCase: '1 uppercase character',      
      number1: '1 number',
      specialChar: '1 special character',
      char8: '8 characters'
    }
    
    for (const [key, value] of Object.entries(validations)) {
      let completed, classname;

      if (passwordErrors[key] === true) {
        completed = 'close'
        classname = this.props.classes.liconred
      } else {
        completed = 'checkmark'
        classname = this.props.classes.licongreen
      }

     required.push(
        <li key={key}>
          <Icon className={classname}>{completed}</Icon>
          <span>{value}</span>
        </li>
      )
    }

    return required
  }

  render() {
    const { classes } = this.props

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component='h6' variant='h6'>
            Reset Your Password
          </Typography>
          <div> 
            <p>Make sure your password has:</p>
            <ul className={classes.nobullets}>
              {
                this.renderValidationItem()
              }
            </ul>
          </div>
          <form className={classes.form}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='password'>Password</InputLabel>
              <Input 
                type='password'
                required
                id='password' 
                name='password' 
                autoComplete='password' 
                autoFocus
                onChange={this.handleChange}
              />
            </FormControl>
            {this.renderApiErrors()}
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!this.disableSubmit()}
              onClick={this.handleSubmit}
            >
              Reset Password
            </Button>
          </form>
        </Paper>
      </main>
    )
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PasswordReset)
