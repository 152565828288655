import { HEADERS } from '../../constants/index'

function baseGetRequest(url) {
  return fetch(
    `${process.env.REACT_APP_API_HOST}v1/${url}`, 
    {
      method: 'GET',
      headers: headersWithAuth()
    }
  )
}

function basePatchRequest(requestBody, url) {
  return fetch(`${process.env.REACT_APP_API_HOST}v1/${url}`, {
    method: 'PATCH',
    headers: headersWithAuth(),
    body: JSON.stringify(requestBody)
  })
}

function basePostRequest(requestBody, url) {
  return fetch(`${process.env.REACT_APP_API_HOST}v1/${url}`, {
    method: 'POST',
    headers: headersWithAuth(),
    body: JSON.stringify(requestBody)
  })
}

function headersWithAuth() {
  return Object.assign(
    {}, 
    HEADERS, 
    {
      'Authorization': localStorage.getItem('token')

    }
  )
}

export { 
  headersWithAuth,
  baseGetRequest,
  basePatchRequest,
  basePostRequest
}