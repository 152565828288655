function mapTitleFromCode(code, mapping) {
  if (code === null || code === '') {
    return "N/A"
  }

  let result = mapping.filter(obj => obj.code === code)
  
  if (result[0] === undefined) {
    return ''
  }

  return result[0].title
}

export {
  mapTitleFromCode
}