// eslint-disable-next-line
const PHONE_PATTERN = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
// eslint-disable-next-line
const BIRTHDATE_PATTERN = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

// Password validations
const PASSWORD_REGEX_8_CHAR =  /^.{8,}$/;
const PASSWORD_REGEX_1_NUM =  /^(?=.{6,20}$)\D*\d/;
const PASSWORD_REGEX_1_SPECIAL_CHAR = /[*@!#%&()^~{}]+/;
const PASSWORD_REGEX_1_UPPERCASE =  /^(?=.*[A-Z])/;

export { 
  PHONE_PATTERN,
  BIRTHDATE_PATTERN,
  PASSWORD_REGEX_8_CHAR,
  PASSWORD_REGEX_1_NUM,
  PASSWORD_REGEX_1_SPECIAL_CHAR,
  PASSWORD_REGEX_1_UPPERCASE
}