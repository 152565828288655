// External
import React, { Component } from 'react'
import { v4 } from "uuid";

// MUI
import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'


// Internal
import styles from './basicTableRowStyles'

class BasicTableRow extends Component {
  renderEdit(objectId, value) {
    if(this.props.edit !== true) {
      return null
    }

    return(
      <TableCell align="left" className={this.props.classes.tableRowKey}>
        <Link onClick={() => { this.props.handleEdit(objectId, value) }}>
          <Icon>edit</Icon>
        </Link>
      </TableCell>
    )
  }

  render() {
    const { classes, objectId } = this.props

    return (
      <TableRow key={ v4() }>
        <TableCell align="right" className={classes.tableRowKey}>
          <Box fontWeight="fontWeightBold">
            {this.props.title}
          </Box>
        </TableCell>
        <TableCell align="left" className={classes.tableRowValue}>
          {this.props.value}
        </TableCell>
        {this.renderEdit(objectId, this.props.value)}
      </TableRow>
    )
  }
}

export default withStyles(styles)(BasicTableRow)
