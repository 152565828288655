const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    color: 'red',
    marginTop: '5px',
    fontSize: '14px'
  },
  nobullets: {
    listStyleType: 'none',
    paddingLeft: '10px'
  },
  licongreen: {
    lineHeight: 'inherit',
    marginRight: '3px',
    fontSize: '1.25rem',
    color: 'green'
  },
  liconred: {
    lineHeight: 'inherit',
    marginRight: '3px',
    fontSize: '1.25rem',
    color: 'red'
  },
  forgotpassword: {
    textDecoration: 'underline',
    marginLeft: '3px'
  }
})

export default styles