import { baseGetRequest, basePatchRequest } from './baseApiUtilities'

function fetchChats() {
  return baseGetRequest('chats')
}

function fetchChat(chatId) {
  return baseGetRequest(`chats/${chatId}`)
}

function fetchChatMessages(chatId, messageCount) {
  return baseGetRequest(`chat_messages/${chatId}?last=${messageCount}`)
}

function fetchSelectedChatMessages(chatId, messageCount, selection) {
  return baseGetRequest(`chat_messages/${chatId}?last=${messageCount}&month=${selection.month}&year=${selection.year}`)
}

function updateChatMessage(messageId, messageBody) {
  return basePatchRequest(messageBody, `chat_messages/${messageId}`)
}

function fetchUser(authToken) {
  return baseGetRequest('users/1234567890')
}

function fetchProfile(authToken) {
  return baseGetRequest('profiles/1234567890')
}

function updateProfile(authToken, profileBody) {
  return basePatchRequest(profileBody, 'profiles/1234567890')
}


export { 
  fetchChats,
  fetchChat,
  fetchChatMessages,
  fetchSelectedChatMessages,
  fetchUser,
  fetchProfile,
  updateProfile,
  updateChatMessage
}