function fetchInviteToken(inviteTokenId) {
  return fetch(`${process.env.REACT_APP_API_HOST}v1/invite_tokens/${inviteTokenId}`, {
    method: 'GET',
    headers: defaultHeaders
  })
}

function updateInviteToken(inviteTokenId, regState) {
  return fetch(`${process.env.REACT_APP_API_HOST}v1/invite_tokens/${inviteTokenId}`, {
    method: 'PATCH',
    headers: defaultHeaders,
    body: JSON.stringify({reg_state: regState})
  })
}

function createRegistration(inviteTokenId, registrationBody) {
  return fetch(`${process.env.REACT_APP_API_HOST}v1/registrations/${inviteTokenId}`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(registrationBody)
  })
}

function createChatValidation(inviteTokenId, buddyPhones) {
  return fetch(`${process.env.REACT_APP_API_HOST}v1/chat_validations/${inviteTokenId}`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(buddyPhones)
  })
}

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export { 
  createChatValidation,
  createRegistration,
  fetchInviteToken,
  updateInviteToken
}