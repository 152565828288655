import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Link from '@material-ui/core/Link'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// Internal files
import styles from './loginPageStyles'
import store from '../../stores/Store';


class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone: '',
      password: '',
      api_errors: ''
    }
    
    this.store = store
    this.handleChange = this.handleChange.bind(this)
    this.handleApiErrors = this.handleApiErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.renderApiErrors = this.renderApiErrors.bind(this)
    this.resetApiErrors = this.resetApiErrors.bind(this)
    this.goToForgotPassword = this.goToForgotPassword.bind(this)
  }

  componentDidMount() {
    this.store.logOut()
    localStorage.setItem('token', null);
    localStorage.setItem('loggedIn', false);
  }

  handleSubmit(event) {
    this.resetApiErrors()

    fetch(`${process.env.REACT_APP_API_HOST}v1/authentications`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          phone: this.state.phone,
          password: this.state.password
        }
      )
    })
    .then(response => {
      if (response.ok) {
        // store the JWT
        response.text().then(json => {
          localStorage.setItem('token', JSON.parse(json).token);
          localStorage.setItem('loggedIn', true);
          this.store.logIn('Joe', JSON.parse(json).token)
          this.props.history.push('/dashboard')
        })
      } else if (response.status !== 201) {
        response.text().then(json => {
          this.handleApiErrors(JSON.parse(json).errors)
        })
      }
    })
  }

  resetApiErrors() {
    this.setState({api_errors: ''})
  }

  handleApiErrors(errorText) {
    this.setState({api_errors: errorText})
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value})  
  }

  goToForgotPassword() {
    this.props.history.push('/forgot-password')
  }

  renderApiErrors() {
    if (this.state.api_errors === '') {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.api_errors}
      </div>
    )
  }


  render() {
    const { classes } = this.props

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component='h6' variant='h6'>
            Login
          </Typography>
          <form className={classes.form}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='phone'>Phone Number</InputLabel>
              <Input 
                required
                id='phone' 
                name='phone' 
                autoComplete='phone' 
                autoFocus
                onChange={this.handleChange}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='password'>Password</InputLabel>
              <Input
                required
                id='password' 
                name='password' 
                autoComplete='password' 
                type='password'
                autoFocus
                onChange={this.handleChange}
              />
            </FormControl>
            {this.renderApiErrors()}
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </form>
          <Typography component='p' variant='p' className={classes.forgotPassword} >
            <Link onClick={this.goToForgotPassword}>Forgot password</Link>
          </Typography>
        </Paper>
      </main>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginPage)
