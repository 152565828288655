import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

// internal libs
import styles from './registrationStyles'

import { 
  fetchInviteToken, 
  updateInviteToken, 
  createRegistration 
} from '../../lib/api_utilities/registrationApiUtilities'

import { 
  registrationBody,
  saveStateBody
} from '../../lib/api_utilities/postBodyFormatters'

import { registrationControllerState } from '../../lib/utilities/defaultStateUtilities'


// form components
import RegistrationPersonalDetails from './RegistrationPersonalDetails'
// import RegistrationScheduleDetails from './RegistrationScheduleDetails'
import RegistrationEditProfile from './RegistrationEditProfile'
import RegistrationBuddiesDetails from './RegistrationBuddiesDetails'
import RegistrationConfirmation from './RegistrationConfirmation'
import RegistrationSuccess from './RegistrationSuccess'

class RegistrationController extends Component {
  constructor(props) {
    super(props)

    this.state = registrationControllerState
    
    // handle bindings
    this.handleChange = this.handleChange.bind(this)
    this.handleApiErrors = this.handleApiErrors.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetProfileState = this.resetProfileState.bind(this)
    
    // error bindings
    this.renderApiErrors = this.renderApiErrors.bind(this)
    this.resetApiErrors = this.resetApiErrors.bind(this)
    
    // schedule bindings
    this.updateFrequency = this.updateFrequency.bind(this)
    this.updatePromptTime = this.updatePromptTime.bind(this)
    
    // buddy bindings
    this.addBuddy = this.addBuddy.bind(this)
    this.removeBuddy = this.removeBuddy.bind(this)
  }

  componentDidMount() { this.validateInviteToken() }

  inviteTokenId() {
    return this.props.match.params.inviteTokenId
  }

  validateInviteToken() {
    fetchInviteToken(this.inviteTokenId()).then(response => {
      if (response.ok) {
        response.text().then(json => {
          let cachedState = JSON.parse(json).invite_token.reg_state
          let newState = {
            valid_invite_token: true,
            validating_token: false,
            invite_token_id: this.inviteTokenId(),
            user_id: JSON.parse(json).user_id, 
            phone: JSON.parse(json).phone
          }

          let updatedState = Object.assign({}, newState, cachedState)
          
          this.setState(updatedState)   
        })
      } else if (response.status === 404) {
        this.setState({
          valid_invite_token: false,
          validating_token: false
        })
      }
    })
  }

  updateInviteToken() {
    updateInviteToken(this.inviteTokenId(), saveStateBody(this.state))
  }

  handleSubmit(event) {
    this.setState({submitting: true})
    this.resetApiErrors()

    createRegistration(this.inviteTokenId(), registrationBody(this.state)).then(response => {
      if (response.ok) {
        this.props.history.push('/registration-success')
      } else if (response.status === 400) {
        response.text().then(json => {
          // Intentional console log for debugging
          console.log('The server returned errors:', JSON.parse(json).errors.message)
          this.handleApiErrors()
        })
      } else if (response.status === 500) {
        response.text().then(json => {
          this.handleApiErrors()
        })
      }
    })
  }

  resetApiErrors() {
    this.setState({api_errors: ''})
  }

  resetProfileState() {
    this.setState({
      ethnicity: '',
      timezone: '',
      country_code: '',
      weight_units: '',
      birthdate: '',
      height: '',
      target_weight: '',
      height_units: '',
      gender: ''
    })
  }

  handleApiErrors() {
    this.setState({
      api_errors: 'Something went wrong. Please try again.',
      submitting: false
    })
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }

  renderApiErrors() {
    if (this.state.api_errors === '') {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.api_errors}
      </div>
    )
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state

    this.setState({
      step: step + 1
    })

    this.updateInviteToken()
  }

  // Back to previous step
  previousStep = () => {
    const { step } = this.state
    this.setState({
      step: step - 1
    })
  }

  updateFrequency(frequency) {
    this.setState({prompt_frequency: frequency})
  }

  updatePromptTime(promptTime) {
    this.setState({prompt_time: promptTime})
  }

  addBuddy(cleanedPhone) {
    let currentBuddies = this.state.buddies
    const newBuddy = {phone: this.state.current_buddy_phone, first_name: this.state.current_buddy_first_name}
    currentBuddies[cleanedPhone] = newBuddy

    this.setState({buddies: currentBuddies, current_buddy_phone: '', current_buddy_first_name: ''})
  }

  removeBuddy(buddyPhone) {
    let currentBuddies = this.state.buddies
    delete currentBuddies[buddyPhone]

    this.setState({buddies: currentBuddies})
  }

  render() {
    const { classes } = this.props
    const { step } = this.state
    const {
      submitting,
      first_name,
      phone,
      invite_token_id,
      buddies,
      current_buddy_phone,
      current_buddy_first_name,
      ethnicity,
      timezone,
      country_code,
      birthdate,
      height,
      target_weight,
      weight_units,
      height_units,
      gender
    } = this.state

    const values = { 
      submitting,
      step,
      first_name,
      phone,
      invite_token_id,
      buddies, 
      current_buddy_phone,
      current_buddy_first_name,
      ethnicity,
      timezone,
      country_code,
      birthdate,
      height,
      target_weight,
      weight_units,
      height_units,
      gender
    }

    if (this.state.validating_token === true) {
      return (
        <div></div>
      )
    } else if (this.state.valid_invite_token === true) {
      switch(step) {
      case 1:
        return (
          <RegistrationPersonalDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case 2:
        return (
          <RegistrationEditProfile
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            handleChange={this.handleChange}
            values={values}
            resetProfileState={this.resetProfileState}
          />
        )
        case 3:
        return (
          <RegistrationBuddiesDetails
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            handleChange={this.handleChange}
            addBuddy={this.addBuddy}
            removeBuddy={this.removeBuddy}
            values={values}
          />
        )
      case 4:
        return (
          <RegistrationConfirmation
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            handleSubmit={this.handleSubmit}
            removeBuddy={this.removeBuddy}
            renderApiErrors={this.renderApiErrors}
            values={values}
          />
        )
      case 5:
        return (
          <RegistrationSuccess 
            values={values}
          />
        )
      default:
        return (
          <RegistrationPersonalDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      }
    } else {
      return(
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Typography component='h6' variant='h6'>
              Your token appears to be invalid.
            </Typography>
            <p>
              Please request a new one <a href='/request-invite'>here</a>.
            </p>
          </Paper>
        </main>
      )
    }
  }
}

RegistrationController.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegistrationController)
