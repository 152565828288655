import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';

import { observer } from "mobx-react"


const styles = {
  appBar: {
    backgroundColor: 'white'
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    color: '#f50057'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: '#f50057',
    textDecoration: 'none'
  },
  loggedInProfileButton: {
    color: '#93CAED'
  },
  notLoggedInProfileButton: {
    color: '#f50057'
  },
  leftIcon: {
    marginRight: 10
  }
};

class MenuAppBar extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
  };

  renderLogin() {
    if (this.props.store.currentUser.isLoggedIn === true) {
      return(
        <MenuItem onClick={() => { this.goToLogOut() }} >
          <Icon className={this.props.classes.leftIcon} >logout</Icon>
          Log Out
        </MenuItem>
      )
    } else {
      return(
        <MenuItem onClick={() => { this.goToLogin() }} >
          <Icon className={this.props.classes.leftIcon} >login</Icon>
          Login
        </MenuItem>)
    }
  }

  goToUserProfile() {
    this.handleClose()
    this.props.history.push('/user-profile')
  }

  goToDashboard() {
    this.handleClose()
    this.props.history.push('/dashboard')
  }

  goToLogin() {
    this.handleClose()
    this.props.history.push('/login')
  }

  goToLogOut() {
    this.handleClose()
    this.props.store.logOut()
    this.props.history.push('/login')
  }

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  profileButtonClass = notLoggedIn => {
    if (notLoggedIn === true) {
      return this.props.classes.notLoggedInProfileButton
    } else {
      return this.props.classes.loggedInProfileButton
    }
  }


  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const isDisabled = this.props.store.currentUser.isLoggedIn !== true

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              FFDiet
            </Typography>
            {auth && (
              <div>
                <IconButton
                  className={this.profileButtonClass(isDisabled)}
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={() => { this.goToDashboard() }} disabled={isDisabled} >Dashboard</MenuItem>
                  <MenuItem onClick={() => { this.goToUserProfile() }} disabled={isDisabled} >Profile</MenuItem>
                  <Divider light />
                  {this.renderLogin()}
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(observer(MenuAppBar));
