const styles = theme => ({
  tableRowKey: {
    width: '40%'
  },
  tableRowValue: {
    width: '60%'
  }
})

export default styles
