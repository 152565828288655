import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'

// Table components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import styles from './registrationStyles'
import BuddiesTable from './BuddiesTable'
import TitleBox from './TitleBox'
import ConfirmationTableRow from './ConfirmationTableRow'
import { mapTitleFromCode } from '../../lib/utilities/titleMappers'
import { 
  supportedHeightUnits,
  supportedCountries,
  supportedGenders
} from './supportedProfileFields'
import { 
  fetchEthnicities,
  fetchTimeZones
} from '../../lib/api_utilities/referenceDataApiUtilities'

class RegistrationConfirmation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      refEthnicities: [],
      refTimeZones: [],
      fetchingRefEthnicities: true,
      fetchingRefTimeZones: true
    }
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchRefEthnicities(),
      this.fetchRefTimeZones()
    ])
  }

  fetchRefTimeZones() {
    fetchTimeZones().then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            refTimeZones: JSON.parse(json),
            fetchingRefTimeZones: false
          })   
        })
      }
    })
  }

  fetchRefEthnicities() {
    fetchEthnicities().then(response => {
      if (response.ok) {
        response.text().then(json => {
          this.setState({
            refEthnicities: JSON.parse(json),
            fetchingRefEthnicities: false
          })   
        })
      }
    })
  }

  renderSubmitInternals() {
    if (this.props.values.submitting === false) {
      return 'Get Started'
    }

    return (
      <CircularProgress 
        color="inherit" 
        size={25} 
        className={this.props.classes.circleSpinner} 
      />
    )
  }

  back = e => {
    e.preventDefault()
    this.props.previousStep()
  }

  render() {
    const fetchingRefEthnicities = this.state.fetchingRefEthnicities
    const fetchingRefTimeZones = this.state.fetchingRefTimeZones
    if (fetchingRefEthnicities === true || fetchingRefTimeZones === true) {
      return null
    }

    const { classes } = this.props
    const buddies = this.props.values.buddies

    // remap profile data
    let timezone = mapTitleFromCode(this.props.values.timezone, this.state.refTimeZones)
    let country = mapTitleFromCode(this.props.values.country_code, supportedCountries)
    let gender = mapTitleFromCode(this.props.values.gender, supportedGenders)
    let height_units = mapTitleFromCode(this.props.values.height_units, supportedHeightUnits)
    let ethnicity = mapTitleFromCode(this.props.values.ethnicity, this.state.refEthnicities)

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <TitleBox currentStep={this.props.values.step} />
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} size="small" aria-label="buddies table">
              <TableBody>
                <ConfirmationTableRow title='Your Name' value={this.props.values.first_name} />
                <ConfirmationTableRow title='Ethnicity' value={ethnicity} />
                <ConfirmationTableRow title='Time Zone' value={timezone} />
                <ConfirmationTableRow title='Country' value={country} />
                <ConfirmationTableRow 
                  title='Target Weight' 
                  value={`${this.props.values.target_weight} ${this.props.values.weight_units}`} 
                />
                <ConfirmationTableRow title='Birthdate' value={this.props.values.birthdate} />
                <ConfirmationTableRow 
                  title='Height' 
                  value={`${this.props.values.height} ${height_units}`} 
                />
                <ConfirmationTableRow title='Gender' value={gender} />
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant='body1' className={this.props.classes.buddyIntroBox} >
            If you added any Buddies to your MMS group then they will be listed below.
          </Typography>
          <BuddiesTable 
            buddies={buddies} 
            removeBuddy={this.props.removeBuddy}
            disableRemoveIcon={true}
          />
          {this.props.renderApiErrors()}
          <Box className={classes.submitBox}>
            <Button
              size='large'
              color='secondary'
              className={classes.submit}
              onClick={this.back}
            >
              <ArrowBackIcon className={classes.arrowBackIcon} /> 
              Back
            </Button>
            <Button
              size='large'
              variant='contained'
              color='primary'
              className={classes.finalSubmit}
              onClick={this.props.handleSubmit}
            >
              {this.renderSubmitInternals()}
            </Button>
          </Box>
        </Paper>
      </main>
    )
  }
}

RegistrationConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RegistrationConfirmation)
