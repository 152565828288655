// TODO move to reference_data in the API
const supportedWeightUnits = [
  {code: 'lbs', title: 'pounds', selected: true},
  {code: 'kg', title: 'kilgorams'}
]

const supportedHeightUnits = [
  {code: 'in', title: 'inches', selected: true},
  {code: 'cm', title: 'centimeters'}
]

const supportedCountries = [
  {code: 'usa', title: 'USA', enabled: true, selected: true},
  {code: 'can', title: 'Canada', enabled: false}
]

const supportedGenders = [
  {code: 'p', title: 'Prefer not to say'},
  {code: 'f', title: 'Female', selected: true},
  {code: 'm', title: 'Male'}
]

// NOT YET IMPLEMENTED
const supportedLanguages = [
  {code: 'en', title: 'English', enabled: true, selected: true},
  {code: 'es', title: 'Spanish', enabled: false}
]

export { 
  supportedWeightUnits,
  supportedHeightUnits,
  supportedLanguages,
  supportedCountries,
  supportedGenders
}
