import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import styles from './registrationStyles'
import { cleanPhone, validPhoneNumber } from '../../lib/utilities/phoneUtilities'

class AddBuddy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      api_errors: ''
    }
  }

  resetApiErrors() {
    this.setState({api_errors: ''})
  }

  handleApiErrors(errorText) {
    this.setState({api_errors: errorText})
  }

  missingFirstName() {
    return !this.props.values.current_buddy_first_name.length > 0
  }

  maxBuddiesAdded() {
    return Object.keys(this.props.buddies).length === 2
  }

  renderNameFieldError() {
    if (!this.missingFirstName()) {
      return null
    }

    return (
       <FormHelperText className={this.props.classes.error}>
          First name is required.
      </FormHelperText>
    )
  }

  renderPhoneErrors() {
    if (this.state.api_errors === '') {
      return null
    }

    return (
      <div className={this.props.classes.error}>
        {this.state.api_errors}
      </div>
    )
  }

  validatePhone() {
    this.resetApiErrors()
    let cleanedPhone = cleanPhone(this.props.values.current_buddy_phone)

    if (Object.keys(this.props.buddies).includes(cleanedPhone)) {
      this.handleApiErrors('Phone number has already been added to this chat.')
    } else if (cleanedPhone === cleanPhone(this.props.values.phone)) {
      this.handleApiErrors('Your phone number is already in this chat.')
    } else if (validPhoneNumber(cleanedPhone)) {
      this.resetApiErrors()
      this.props.addBuddy(cleanedPhone)
    } else {
      this.handleApiErrors('Phone number is not in a valid format.')
    }
  }

  render() {
    const { classes } = this.props
    let disabledAdd = this.missingFirstName()
    let disabledAddBuddy = this.maxBuddiesAdded()

    return (
      <div>
        <Button
          size='large'
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={this.props.handleOpen}
          disabled={disabledAddBuddy}
          
        >
          <AddCircleIcon className={classes.addCircle} />
          Add Buddy
        </Button>
        <Modal
          open={this.props.showAddBuddy}
          onClose={this.props.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.addModal}>
            <HighlightOffIcon onClick={this.props.handleClose} />
            <form className={classes.form}>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='current_buddy_first_name'>First Name</InputLabel>
                <Input 
                  required
                  id='current_buddy_first_name' 
                  name='current_buddy_first_name' 
                  autoFocus 
                  value={this.props.values.current_buddy_first_name}
                  onChange={this.props.handleChange}
                />
                {this.renderNameFieldError()}
              </FormControl>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='current_buddy_phone'>Phone Number</InputLabel>
                <Input
                  required
                  id='current_buddy_phone' 
                  name='current_buddy_phone'
                  value={this.props.values.current_buddy_phone}
                  onChange={this.props.handleChange}
                />
                <FormHelperText id="my-helper-text">
                Must be a valid 10-digit US phone number e.g. 212-212-2121
                </FormHelperText>
              </FormControl>
              {this.renderPhoneErrors()}
              <Button
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={() => this.validatePhone()}
                  disabled={disabledAdd}
                >
                ADD
              </Button>
            </form>
          </div>
        </Modal>
      </div>
    )
  }
}

AddBuddy.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AddBuddy)